html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    border: 0;
}

body {
    font-family: 'Amiko', sans-serif;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    background-color: #fff;
    color: #000;
}

.page-container {
    padding: 1rem;
}

.text-heading, .text-subheading {
    font-family: 'Anton', sans-serif;
    font-size: 32px;
}

.quote {
    font-family: 'Inder', sans-serif;
    font-weight: 400;
}

@media (min-width: 760px) {
    body {
        font-size: 18px;
    }

    .text-heading {
        font-size: 64px;
    }

    .text-subheading {
        font-size: 42px;
    }
}

@media (min-width: 1030px) {
    body {
        font-size: 24px;
    }

    .quote {
        font-size: 20px;
    }
}