.videos-quote {
    max-width: 350px;
    margin: 0.5rem auto;
}

.video {
    margin: 0.5rem;
}

.videos-subscribe-text {
    margin-top: 1rem;
    margin-bottom: 0;
}

.videos-join-text {
    margin-top: 2.6rem;
    margin-bottom: 0;
}

@media (min-width: 760px) {
    .videos-quote {
        max-width: 1440px;
        margin: 1rem auto;
    }

    .videos-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-top: 1.5rem;
    }

    .video {
        margin: 0;
    }

    .videos-join-text {
        margin-top: 1.5rem;
    }
}

@media (min-width: 1200px) {
    .videos-container {
        margin-top: 2.8rem;
        margin-bottom: 4rem;
    }

    .videos-join-text {
        margin-top: 3.8rem;
    }
}