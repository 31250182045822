.header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 100%;
    background-color: #000;
    color: #fff;
}

.homepage-header-title-last, .header-title {
    background-color: #ed1f1f;
    border: 2px solid #fff;
    padding: 0.2rem 0.7rem;
}

.homepage-header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
}

.homepage-header-title-last {
    font-size: 26px;
    margin-left: 0.4rem;
}

.homepage-header-subtitle {
    font-size: 12px;
    padding: 0 0.5rem;
    margin-bottom: 0;
}

.homepage-header-subtitle-first {
    font-weight: 700;
}

@media (min-width: 760px) {
    .header-container {
        height: 175px;
    }

    .homepage-header-title {
        margin-bottom: 1rem;
    }

    .homepage-header-title-last {
        font-size: 52px;  
        margin-left: 0.8rem;
    }

    .homepage-header-subtitle {
        font-size: 24px;
    }
}

@media (min-width: 1030px) {
    .header-container {
        height: 225px;
    }
}